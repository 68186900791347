<template>
  <div class="home_container">
    <div class="header"></div>
    <van-search
      @click="navRegister"
      placeholder="搜索科室，医生"
      background="transparency"
    />
    <div class="row">
      <van-grid :border="false" :column-num="3">
        <van-grid-item @click="navRegister">
          <img src="../../assets/guahao.png" class="img" />
          <span>预约挂号</span>
        </van-grid-item>
        <van-grid-item @click="navOutpatient">
          <img src="../../assets/menzhen.png" class="img" />
          <span>门诊缴费</span>
        </van-grid-item>
        <van-grid-item @click="navReport">
          <img src="../../assets/baogao.png" class="img" />

          <span>查看报告</span>
        </van-grid-item>
        <van-grid-item @click="navNrhc">
          <img src="../../assets/jiandang.png" class="img" />
          <span>建档绑卡</span>
        </van-grid-item>
        <van-grid-item @click="navInpatient">
          <img src="../../assets/zhuyuan.png" class="img" />
          <span>住院清单</span>
        </van-grid-item>
        <van-grid-item @click="navCovid">
          <img src="../../assets/hesuan.png" class="img" />
          <span>核酸检测</span>
        </van-grid-item>
        <van-grid-item @click="navExReport">
          <img src="../../assets/tj.png" class="img" />
          <span>体检报告</span>
        </van-grid-item>
        <van-grid-item @click="navDeposit">
          <img src="../../assets/anjin.png" class="img" />
          <span>住院按金</span>
        </van-grid-item>
        <!-- <van-grid-item @click="navAntihelion">
          <img src="../../assets/4.png"
               class="img" />
          <span>抗原检测</span>
        </van-grid-item> -->
      </van-grid>
    </div>
    <!-- <div class="row">
      <div class="title">体检服务</div>
      <van-grid :border="false"
                :column-num="4">
        <van-grid-item>
          <img src="../../assets/hesuan.png"
               class="img" />
          <span>核酸检测</span>
        </van-grid-item>
        <van-grid-item @click="navExamine">
          <img style="height: 54px;
                      margin-top:2px"
               src="../../assets/tijian.png"
               class="img" />
          <span style="height: 10px;"></span>
          <span>体检预约</span>
        </van-grid-item>
        <van-grid-item @click="navExReport">
          <img src="../../assets/tj.png"
               class="img" />
          <span>体检报告</span>
        </van-grid-item>
        <van-grid-item @click="navTeam">
          <img src="../../assets/tuanjian.png"
               class="img" />
          <span>团队体检</span>
        </van-grid-item>
      </van-grid>
      <div class="line"></div>
    </div>
    <div class="row">
      <div class="title">快捷服务</div>
      <van-grid :border="false"
                :column-num="4">
        
        <van-grid-item @click="navDeposit">
          <img src="../../assets/anjin.png"
               class="img" />
          <span>住院按金</span>
        </van-grid-item>
        <van-grid-item @click="navInvoice">
          <img src="../../assets/fapiao.png"
               class="img" />
          <span>电子发票</span>
        </van-grid-item>
        <van-grid-item @click="navQuestionnaire">
          <img src="../../assets/wenjuan.png"
               class="img" />
          <span>病史问卷</span>
        </van-grid-item>
      </van-grid>
    </div> -->
  </div>
</template>
<script>
import { jsonPost, formPost } from "@/baseAPI";
import qs from "qs";
import encrypt from "@/utils/encryptDES.js";
export default {
  name: "Home",
  data() {
    return {
      code: ""
    };
  },
  created() {
    if (window.localStorage.getItem("openId") === null) {
      this.getCode();
    } else {
      this.getAllUseInfo();
    }
  },
  methods: {
    // 获取code
    getCode() {
      let local = window.location.href;
      let appid = "wxcdae494f7e9422e7";
      window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${encodeURIComponent(
        local
      )}&response_type=code&scope=snsapi_base&state=123&#wechat_redirect`;

      // window.location.href = "https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxcdae494f7e9422e7&redirect_uri=http://tssywx.tssrmyy.com&response_type=code&scope=snsapi_base&state=STATE#wechat_redirect"
      let str = window.location.href;
      let code = str.split("code=")[1].split("&")[0];
      this.code = code;
      this.login(code);
    },
    //获取openId
    async login(code) {
      this.$toast.loading({
        duration: 0,
        message: "加载中...",
        forbidClick: true
      });
      let data = qs.stringify({
        code: code
      });
      const { data: res } = await formPost("/wxapp/login", data);
      this.$toast.clear();
      if (res.code === 0) {
        window.localStorage.setItem("openId", res.data.openid);
        window.location.href = "http://tssywx.tssrmyy.com/wsyb/#/";
        // this.getUserInfo(res.data.accessToken, res.data.openid);
      } else {
        this.$toast.fail(res.msg);
      }
    },
    //获取unionId
    async getUserInfo(accessToken, openid) {
      this.$toast.loading({
        duration: 0,
        message: "加载中...",
        forbidClick: true
      });
      let data = qs.stringify({
        accessToken: accessToken,
        openid: openid
      });
      const { data: res } = await jsonPost("/wxapp/getUserInfo", data);
      this.$toast.clear();
      if (res.code === 0) {
        window.localStorage.setItem("unionId", res.data.unionid);
        this.getAllUseInfo();
      } else {
        this.$toast.fail(res.msg);
      }
    },
    //是否建档
    async getAllUseInfo() {
      // this.$toast.loading({
      //   duration: 0,
      //   message: "加载中...",
      //   forbidClick: true
      // });
      let data = qs.stringify({
        openId: window.localStorage.getItem("openId")
      });
      const { data: res } = await formPost(
        "/wxapp/userBindRecord/userBindRecord/getAllUseInfo",
        data
      );
      console.log(res);
      this.$toast.clear();
      if (res.code === 0) {
      } else {
        this.$toast.fail("暂无建档，请先建档");
        console.log(res.code);
        // setTimeout(() => {
        //   this.$router.push({
        //     path: "/file"
        //   });
        // }, 2000);
      }
    },
    navExamine() {
      this.$router.push({
        path: "/examine"
      });
    },
    navCovid() {
      this.$router.push({
        path: "/covid"
      });
    },
    navRegister() {
      this.$router.push({
        path: "/register"
      });
      // this.$toast.fail("暂未开放")
    },
    navOutpatient() {
      this.$router.push({
        path: "/outpatient"
      });
    },
    navReport() {
      this.$router.push({
        path: "/report"
      });
    },
    navAntihelion() {
      this.$router.push({
        path: "/antihelion"
      });
    },
    navNrhc() {
      this.$router.push({
        path: "/file"
      });
    },
    navInpatient() {
      this.$router.push({
        path: "/inpatient"
      });
    },
    navDeposit() {
      this.$router.push({
        path: "/deposit"
      });
    },
    navInvoice() {
      this.$router.push({
        path: "/invoice"
      });
    },
    navQuestionnaire() {
      this.$router.push({
        path: "/questionnaire",
        query: {
          type: "0"
        }
      });
    },
    navExReport() {
      this.$router.push({
        path: "/exReport"
      });
    },
    navTeam() {
      this.$router.push({
        path: "/team"
      });
    }
  }
};
</script>
<style scope>
.home_container {
  padding-bottom: 100px;
}

.home_container .header {
  position: relative;
  width: 100%;
  height: 400px;
  background-repeat: no-repeat;
  background-size: 100% 400px;
  background-image: url("http://tssywx.tssrmyy.com/wxxcx/wx/home.png");
}

.line {
  height: 10px;
  background-color: #f9f9f9;
}

.home_container .title {
  height: 80px;
  font-size: 40px;
  line-height: 80px;
  border-bottom: 2px solid #eee;
  padding-left: 30px;
  font-weight: bold;
}

.home_container .van-grid-item {
  font-size: 30px;
}

.home_container .img {
  margin-top: -10px;
  margin-bottom: 10px;
  /* width: 130px; */
  height: 130px;
}
</style>
